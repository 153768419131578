import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import BlockContent from '../block-content';
import styles from './class-preview.module.css';

const CalssPreview = ({ heroImage, slug, title, code, _rawDesc }) => (
  <Link className={styles.root} to={`/classes/${slug.current}`}>
    <div className={styles.leadMediaThumb}>
      {heroImage && heroImage.asset && (
        <Img fluid={heroImage.asset.fluid} alt={heroImage.alt} style={{ position: 'static' }} />
      )}
      <h3 className={styles.title}>
        <p>{title}</p>
        <p>{code}</p>
        <BlockContent blocks={_rawDesc} />
      </h3>
    </div>
  </Link>
);

export default CalssPreview;
