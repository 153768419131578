import React from 'react';
import ReactPlayer from 'react-player';
import layoutStyles from './layout/layout.module.css';
import styles from '../styles/video.module.css';
import { responsiveTitle3 } from '../styles/typography.module.css';
import { cn } from '../lib/helpers';

export default function VideoPlayer({ url, thumbnail = false, lightbox = false, playing = false, caption = null }) {
  return (
    <div className={lightbox ? cn(styles.videoWrapper, styles.videoWrapperLightbox) : styles.videoWrapper}>
      {caption && (
        <>
          <h3 className={cn(responsiveTitle3, layoutStyles.overlayTitle)}>{caption}</h3>
          <div className={layoutStyles.overlay}></div>
        </>
      )}
      <ReactPlayer url={url} controls={!thumbnail} light={thumbnail} playing={playing} width="100%" height="100%" />
    </div>
  );
}
