import React, { useState } from 'react';
import Select from 'react-select';
import { uniqBy } from 'lodash';

import styles from './filter-bar.module.css';
import { cn } from '../../lib/helpers';

const FilterStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'white',
    border: 'white 1px solid',
    width: '10em',
    borderRadius: '0',
    marginRight: '1em',
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#ee3224',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#0f131a',
    color: 'white',
    border: '1px solid white',
    borderRadius: '0',
    width: '15em',
    padding: '1em 0',
    zIndex: 10,
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isFocused ? 'white' : 'transparent',
    color: isFocused ? 'black' : 'white',
  }),
};

const FilterBar = ({ handleClick, items, placeholders, showFilters, enableTerms = false }) => {
  const [selOption, setSelOption] = useState([
    {
      label: 'All Schools',
      value: 'Schools',
    },
    {
      label: 'All Courses',
      value: 'Courses',
    },
    {
      label: 'All Terms',
      value: 'Terms',
    },
  ]);
  const showPrimaryFilter = showFilters && items?.length;
  const availableStudents = items[2]?.length && items[2].filter(({ term }) => term);
  const terms = availableStudents?.length && availableStudents.map(({ term }) => ({ value: term, label: term }));
  const uniqueTerms = uniqBy(terms, 'value');
  uniqueTerms.unshift({ value: placeholders[2], label: `All ${placeholders[2]}` });

  const onChange = (data, idx) => {
    let tempSelOption = selOption;
    tempSelOption[idx].label = data.label;
    tempSelOption[idx].value = data.value;

    if (idx === 0) {
      tempSelOption[1].label = `All ${placeholders[1]}`;
      tempSelOption[1].value = placeholders[1];
    }
    setSelOption([...tempSelOption]);
    handleClick(tempSelOption);
  };

  return (
    <div className={styles.root}>
      <div className={cn(styles.wrapper, styles.wrapperDouble)}>
        <div className={cn(styles.filterText, styles.filterTextDouble)}>Filter by:</div>
        {items?.length &&
          showPrimaryFilter &&
          items.map((item, idx) => {
            if (idx < 2) {
              item?.length && item.sort();
              const options = item.map(({ title }) => ({ value: title, label: title }));
              options.unshift({ value: placeholders[idx], label: `All ${placeholders[idx]}` });
              return (
                <Select
                  key={idx}
                  styles={FilterStyles}
                  value={options.find(({ value }) => value === selOption[idx].value)}
                  options={options}
                  onChange={(data) => onChange(data, idx)}
                />
              );
            }
          })}
        {enableTerms && (
          <Select
            styles={FilterStyles}
            value={uniqueTerms.find(({ value }) => value === selOption[2].value)}
            options={uniqueTerms}
            onChange={(data) => onChange(data, 2)}
          />
        )}
      </div>
    </div>
  );
};

export default FilterBar;
