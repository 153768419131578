import React from 'react';
import ClassPreview from './class-preview';
import styles from './classes-grid.module.css';

const ClassesGrid = ({ classes }) => {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {classes &&
          classes.map((course) => (
            <li className={styles.gridCell} key={course.id}>
              <ClassPreview {...course} />
            </li>
          ))}
      </ul>
    </div>
  );
};

ClassesGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
};

export default ClassesGrid;
