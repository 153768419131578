import React, { useState, useRef, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import BlockContent from '../block-content';
import FilterArtworkBar from '../grids/filter-artwork-bar';
import ColumnGrid from '../grids/column-grid';
import PaginationBar from '../grids/pagination-bar';
import Container from '../layout/container';
import Section from '../sections/section';
import { mapEdgesToNodes, sortByName, sortByTitle, paginate } from '../../lib/helpers';

const MAX_RESULTS = 999;

const SectionProjectsGrid = ({ section }) => {
  const {
    _key,
    _rawIntro,
    _rawOutro,
    backgroundColor,
    showPagination,
    showFilters,
    students,
    limitResults,
    narrowWidth,
  } = section;

  const limit = limitResults && limitResults > 0 ? limitResults : MAX_RESULTS;
  const rootRef = useRef();

  return (
    <StaticQuery
      key={_key}
      query={artworkQuery}
      render={({ allStudents, allSchools, allCourses }) => {
        const allStudentsSorted = sortByName(mapEdgesToNodes(allStudents));
        const featuredStudents = [...(students.length ? students : allStudentsSorted)].slice(0, limit);

        const [artWorks, setArtWorks] = useState([]);
        const [filteredStudents, setFilteredStudents] = useState(featuredStudents);
        const [filteredCourses, setFilteredCourses] = useState();
        const [isChangeCourse, setIsChangeCourse] = useState(true);

        const [pages, setPages] = useState(0);
        const [paginationLocation, setPaginationLocation] = useState([0, 1]);

        useEffect(() => {
          handleArtWorks();
          filterAvailableCourses();
        }, [filteredStudents]);

        useEffect(() => {
          const pgData = paginate(1, artWorks.length);
          setPages(pgData?.pages?.length);
          setPaginationLocation([0, pgData?.endIndex]);
        }, [artWorks]);

        const filterAvailableSchools = () => {
          let schools = [];
          featuredStudents.map(({ school }) => {
            !schools.includes(school.slug.current) && schools.push(school.slug.current);
          });

          schools = allSchools.nodes.filter((school) => schools.includes(school.slug.current));
          return sortByTitle(schools);
        };

        const filterAvailableCourses = () => {
          let courses = [];
          courses &&
            courses.length &&
            filteredStudents.map(({ course }) => {
              !courses.includes(course._id) && courses.push(course._id);
            });

          courses = allCourses.nodes.filter((course) => courses.includes(course._id));
          isChangeCourse && setFilteredCourses(courses);
        };

        const handleFilter = (filters) => {
          let tempStudents = featuredStudents;
          if (filters[0].value !== 'Schools') {
            tempStudents = tempStudents.filter((student) => student.school.title === filters[0].value);
            setIsChangeCourse(true);
          } else {
            setIsChangeCourse(true);
          }
          if (filters[1].value !== 'Courses') {
            tempStudents = tempStudents.filter((student) => student.course.title === filters[1].value);
            setIsChangeCourse(false);
          }
          if (filters[2].value !== 'Terms') {
            tempStudents = tempStudents.filter((student) => student.term === filters[2].value);
          }
          setFilteredStudents(tempStudents);
        };

        const handleArtWorks = () => {
          let tempArtWorks = [];
          filteredStudents.map(({ name, term, heroImage, portfolio }) => {
            heroImage?.length &&
              heroImage.map((item) => {
                item.caption = `${item.title ? item.title + ' - ' : ''}${name}`;
                tempArtWorks.push(item);
              });
            portfolio?.length &&
              portfolio.map((item) => {
                if (item?._type === 'video') {
                  item.caption = `${item.title ? item.title + ' - ' : ''}${name}`;
                } else if (item?._type === 'fileUpload') {
                  item.pdfTitle = `${item.title ? item.title + ' - ' : ''}${name}`;
                } else {
                  item.figureTitle = `${item.title ? item.title + ' - ' : ''}${name}`;
                }
                tempArtWorks.push(item);
              });
          });
          setArtWorks([...tempArtWorks]);
        };

        const handlePagination = (num, projs = artWorks) => {
          const paginationData = paginate(num, projs.length);
          setPages(paginationData.pages.length);
          setPaginationLocation([paginationData.startIndex, paginationData.endIndex]);
          scroll({
            top: rootRef?.current?.offsetTop + 355,
            behavior: 'smooth',
          });
        };

        return (
          <div style={{ backgroundColor: `${backgroundColor?.value}` }} ref={rootRef}>
            {_rawIntro && (
              <Section color={backgroundColor?.value} alignment="center">
                <Container narrower={narrowWidth}>
                  <BlockContent blocks={_rawIntro} />
                </Container>
              </Section>
            )}

            <Section color={backgroundColor?.value} flush>
              {showFilters && filteredCourses && (
                <FilterArtworkBar
                  handleClick={handleFilter}
                  items={[filterAvailableSchools(), sortByTitle(filteredCourses), featuredStudents]}
                  placeholders={['Schools', 'Courses', 'Terms']}
                  showFilters
                  enableTerms={false}
                />
              )}
              <ColumnGrid
                items={
                  showPagination
                    ? artWorks.slice(paginationLocation[0], paginationLocation[1])
                    : artWorks.slice(0, limit)
                }
                type="assets"
                media
              />
              {showPagination && <PaginationBar pages={pages} handleClick={handlePagination} isDark={false} />}
            </Section>

            {_rawOutro && (
              <Section color={backgroundColor?.value} alignment="center">
                <Container narrower={narrowWidth}>
                  <BlockContent blocks={_rawOutro} />
                </Container>
              </Section>
            )}
          </div>
        );
      }}
    />
  );
};

export default SectionProjectsGrid;

const artworkQuery = graphql`
  query ArtworkQueryQuery {
    allStudents: allSanityStudent {
      edges {
        node {
          ...StudentPreview
        }
      }
    }
    allSchools: allSanitySchool {
      nodes {
        ...School
      }
    }
    allCourses: allSanityCourse {
      nodes {
        ...ClassPreview
      }
    }
  }
`;
