import React from 'react';
import { Document, Page } from 'react-pdf';
import typeStyles from '../../styles/typography.module.css';
import styles from './file-preview.module.css';
import useWindowSize from '../../lib/useWindowSize';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { cn } from '../../lib/helpers';

export default function FilePreview({ file, caption, title = caption, pdfTitle, thumbnail = false }) {
  if (!file || !file.asset || !file.asset.url) {
    return null;
  }

  const { asset } = file;
  const size = useWindowSize();

  // assume PDFs are verical and use max height
  let height = size.height * 0.66;

  if (thumbnail) {
    height = 320;
  }

  const onLoadError = (...args) => {
    console.log('PDF Error', [...args]);
  };
  return (
    <Document
      file={asset.url}
      onLoadError={onLoadError}
      className={thumbnail ? styles.pdfDocument : styles.pdfLightBox}
    >
      <Page pageIndex={0} height={height} />
      {thumbnail && (
        <>
          <h3 className={cn(typeStyles.responsiveTitle3, styles.overlayTitle)}>{pdfTitle}</h3>
          <div className={styles.overlay}></div>
        </>
      )}
      {!thumbnail && (
        <>
          <h4 className={typeStyles.responsiveTitle2}>{title}</h4>
          <a className={typeStyles.responsiveTitle3} href={asset.url} title={title} target="_blank">
            {asset.originalFilename}
            <PictureAsPdfIcon className={styles.pdfIcon} />
          </a>
        </>
      )}
    </Document>
  );
}
