import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import BlockContent from '../block-content';
import Container from '../layout/container';
import Section from '../sections/section';
import GridComponent from '../schools/classes-grid';
import { mapEdgesToNodes, sortByTitle } from '../../lib/helpers';

// see data/fragments/PageContent
const SectionClassesGrid = ({ section }) => {
  const {
    _key,
    _rawIntro,
    _rawOutro,
    backgroundColor,
    narrowWidth,
    // gridStyle,
    courses: selectedClasses,
  } = section;

  return (
    <StaticQuery
      key={_key}
      query={allClassesQuery}
      render={({ allClasses }) => {
        const allClassesSorted = sortByTitle(mapEdgesToNodes(allClasses));
        const courses = selectedClasses.length ? selectedClasses : allClassesSorted;

        return (
          <div style={{ backgroundColor: `${backgroundColor?.value}` }}>
            {_rawIntro && (
              <Section color={backgroundColor?.value} alignment="center">
                <Container narrower={narrowWidth}>
                  <BlockContent blocks={_rawIntro} />
                </Container>
              </Section>
            )}
            {courses?.length && <GridComponent classes={courses} />}
            {_rawOutro && (
              <Section color={backgroundColor?.value} alignment="center">
                <Container narrower={narrowWidth}>
                  <BlockContent blocks={_rawOutro} />
                </Container>
              </Section>
            )}
          </div>
        );
      }}
    />
  );
};

export default SectionClassesGrid;

const allClassesQuery = graphql`
  query AllClassesQuery {
    allClasses: allSanityCourse {
      edges {
        node {
          ...ClassPreview
        }
      }
    }
  }
`;
